var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          ref: "employee-work-hours-form",
          attrs: {
            id: "employee-work-hours-form",
            submitAction: _vm.update,
            submitSuccessAction: _vm.updateSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasMultipleOffices,
                  expression: "hasMultipleOffices"
                }
              ],
              staticClass: "form-group"
            },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "officeId",
                  placeholder: _vm.$t("offices.office"),
                  dataSource: _vm.offices,
                  fields: { text: "name", value: "id" }
                },
                model: {
                  value: _vm.data.officeId,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "officeId", $$v)
                  },
                  expression: "data.officeId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-datepicker", {
                attrs: {
                  id: "startDateTime",
                  placeholder: _vm.$t("shared.validFrom")
                },
                model: {
                  value: _vm.data.startDateTime,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "startDateTime", $$v)
                  },
                  expression: "data.startDateTime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-datepicker", {
                attrs: {
                  id: "endDateTime",
                  placeholder: _vm.$t("shared.validTo")
                },
                model: {
                  value: _vm.data.endDateTime,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "endDateTime", $$v)
                  },
                  expression: "data.endDateTime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "form-group",
              staticStyle: { "margin-top": "30px" }
            },
            [
              _c("table", [
                _c("colgroup", [
                  _c("col", { attrs: { width: "120px" } }),
                  _c("col", { attrs: { width: "160px" } }),
                  _c("col", { attrs: { width: "8px" } }),
                  _c("col", { attrs: { width: "160px" } })
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.getDaysOfWeek(), function(dayOfWeek) {
                      return _vm._l(
                        _vm.getWorkHoursForDayOfWeek(dayOfWeek),
                        function(workHour, index) {
                          return _c("tr", { key: workHour.id }, [
                            _c(
                              "td",
                              [
                                index == 0
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getNameOfWeek(
                                              workHour.dayOfWeek,
                                              _vm.$i18n.locale
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "td",
                              [
                                _c("app-timepicker", {
                                  attrs: {
                                    id: "startTime_" + workHour.id,
                                    openOnFocus: true
                                  },
                                  model: {
                                    value: workHour.startTime,
                                    callback: function($$v) {
                                      _vm.$set(workHour, "startTime", $$v)
                                    },
                                    expression: "workHour.startTime"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("td", [_vm._v("-")]),
                            _c(
                              "td",
                              [
                                _c("app-timepicker", {
                                  attrs: {
                                    id: "endTime_" + workHour.id,
                                    openOnFocus: true
                                  },
                                  model: {
                                    value: workHour.endTime,
                                    callback: function($$v) {
                                      _vm.$set(workHour, "endTime", $$v)
                                    },
                                    expression: "workHour.endTime"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _c("app-button", {
                                  attrs: {
                                    type: "button",
                                    iconCss: "e-delete e-icons",
                                    title: _vm.$t("employees.deleteWorkTime")
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.removeHours(workHour.id)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                index == 0
                                  ? [
                                      _c("app-button", {
                                        attrs: {
                                          type: "button",
                                          iconCss: "e-add e-icons",
                                          title: `${_vm.$t(
                                            "employees.addWorkTimeFor"
                                          )} ${_vm.getNameOfWeek(
                                            workHour.dayOfWeek,
                                            _vm.$i18n.locale
                                          )}`
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.addHours(
                                              workHour.dayOfWeek
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        }
                      )
                    })
                  ],
                  2
                )
              ])
            ]
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.edit",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }